import React from "react"
import LogDetailView from "../LogDetailView"
import styled from "styled-components"

class LogView extends React.Component{
  state = {
    details: [],
    message: "",
    isStackTraceOpened: false,
    isOthersOpened: false,
  }
  constructor(props){
    super(props)
    this.state = {details: this.props.log.details}
  }

  handleDelete = (event) => {
    event.preventDefault()
    const url = "https://manjubox.net/php/elview.php"
    const data = new FormData()
    data.append("method", "delete_log")
    data.append("hash", this.props.log.hash)

    fetch(url, {
      method: "POST",
      body: data,
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.status==="success"){
        this.props.onDeleteLog(this.props.log.hash)
      }
      else{
        this.setState({message:json.message})
      }
    })
  }
  handleDeleteDetail=(detail)=>{
    this.setState({details: this.state.details.filter((d) => d !== detail)})
  }

  handleToggleStackTrace = (event) => {
    event.preventDefault()
    this.setState({isStackTraceOpened: !this.state.isStackTraceOpened})
  }

  handleToggleOthers=(event)=>{
    event.preventDefault()
    this.setState({isOthersOpened: !this.state.isOthersOpened})
  }

  handleSortByDate =(a,b)=>{
    if(a.date > b.date){
      return -1
    }
    else if(a.date < b.date){
      return 1
    }
    else{
      return 0
    }
  }

  render(){
    return(
      <StyledDiv>
        <p><button type="submit" onClick={this.handleDelete} className="deleteLogButton">×</button> {this.props.log.count} / {this.props.log.title}</p>
        <p onClick={this.handleToggleStackTrace}>{this.state.isStackTraceOpened ? "- スタックトレース" : "+ スタックトレース"}</p>
        {this.state.isStackTraceOpened ? <pre>{this.props.log.trace}</pre> : null}

        {this.state.details.filter(detail=>detail.msg.length>0).sort(this.handleSortByDate).map(detail=><LogDetailView key={detail.hash+detail.date+detail.ip} detail={detail} onDelete={this.handleDeleteDetail}/>)}
        
        <p onClick={this.handleToggleOthers}>{this.state.isOthersOpened ? "-" : "+"} 他 {this.state.details.filter(detail=>detail.msg.length == 0).length}件</p>
        {this.state.isOthersOpened ? this.state.details.filter(detail=>detail.msg.length == 0).sort(this.handleSortByDate).map(detail=><LogDetailView key={detail.hash+detail.date+detail.ip} detail={detail} onDelete={this.handleDeleteDetail}/>) : null}
        <hr/>
      </StyledDiv>
    )
  }
}
export default LogView

const StyledDiv = styled.div`
  .deleteLogButton{
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    margin-right: 0.5rem;
    
  }
  hr{
    margin: 1rem 0;
  }
`