import { array } from "prop-types"
import React from "react"
import LogView from "../LogView"

class LogsView extends React.Component{
  state = {
    logs: [],
    message: "",
  }

  handleDeleteLog = (hash) => {
    this.setState({logs: this.state.logs.filter((log) => log.hash !== hash)})
  }

  componentDidMount(){
    if(this.props.isLocal){
      const sampleDetail ={
        hash: "sample hash",
        date: "2022/01/01 00:00:00",
        mail: "sample mail",
        msg: "sample msg\r\nsample msg\r\nsample msg\r\nsample msg\r\n",
        env: "sample env",
        ip: "sample ip",
        version: "sample version",
      }
      const sampleLog = {
        hash: "sample hash",
        title: "sample title",
        count: "1",
        trace: "sample trace",
        details: [
          sampleDetail,
          sampleDetail,
          sampleDetail,
        ]
      }
      this.setState({logs: [sampleLog, sampleLog, sampleLog]})
      return;
    }

    const url = "https://manjubox.net/php/elview.php"
    const data = new FormData()
    data.append("method", "get_logs")
    
    fetch(url,{
        method: "POST",
        body: data,
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.status=="success"){
        this.setState({logs: json.logs})
      }
      else{
        this.setState({message:json.message})
      }
    })
    .catch((error) => {
      this.setState({message:error})
    })
  }

  handleSortLog = (a,b) => {
    var messagedDetailsCountA = a.details.filter(detail=>detail.msg.length>0).length
    var messagedDetailsCountB = b.details.filter(detail=>detail.msg.length>0).length
    if(messagedDetailsCountA < messagedDetailsCountB){
      return 1
    }
    else if(messagedDetailsCountA > messagedDetailsCountB){
      return -1
    }
    else if(a.details.length < b.details.length){
      return 1
    }
    else if(a.details.length > b.details.length){
      return -1
    }
    return 0
  }
  
  render(){
    return(
      <>
        {this.state.message}
        {this.state.logs.sort(this.handleSortLog)
                        .map((log) => <LogView key={log.hash} 
                                               log={log} 
                                               onDeleteLog={this.handleDeleteLog}/>)}
      </>
    )
  }
}
export default LogsView