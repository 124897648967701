import React from "react"

class LoginView extends React.Component{
  state = {
    username:"",
    password:"",
    message:"",
  }

  handleUpdate = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  handleSubmit=(e)=>{
    e.preventDefault()

    if(this.props.isLocal)
    {
      this.props.onLogin("");
      return;
    }

    const url = "https://manjubox.net/php/elview.php"
    const data = new FormData()
    data.append("method", "login")
    data.append("user", this.state.username)
    data.append("password", this.state.password)
    fetch(url, {
      method: "POST",
      body: data,
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.status==="success"){
        this.props.onLogin(this.state.username)
      }else{
        this.setState({
          message:json.message,
        })
      }
    })
    .catch((error) => {
      this.setState({message:error})
    })
  }

  render(){
    return(
      <>
        <form
          onSubmit={this.handleSubmit}>
          <label>ユーザー名</label>
          <input type="text" value={this.state.username} onChange={this.handleUpdate} name="username" />
          <label>パスワード</label>
          <input type="password" value={this.state.password} onChange={this.handleUpdate} name="password" />
          <span>{this.state.message}</span>
          <button type="submit">ログイン</button>
        </form>
      </>
    )
  }
}
export default LoginView