import React from 'react'
import Layout from "../components/Layout"
import styled from "styled-components"
import { theme } from "../components/Shared/styles-global"
import { setThemeVars } from "../util/theme-helper"
import ErrorLogView from '../components/ErrorLogView'
import BlockRobot from '../components/BlockRobot'

class ErrorLogViewPage extends React.Component {
  render() {
    return (
      <Layout sidebar={false}>
        <BlockRobot/>
        <ErrorLogView />
      </Layout>
    )
  }
}

export default ErrorLogViewPage