import React from "react"
import styled from "styled-components"

class LogDetailView extends React.Component{
  state = {
    message: "",
    isDetailOpened: false,
  }

  handleDelete =(event)=>{
    event.preventDefault()
    const url = "https://manjubox.net/php/elview.php"
    const data = new FormData()
    data.append("method", "delete_detail")
    data.append("hash", this.props.detail.hash)
    data.append("date", this.props.detail.date)

    fetch(url, {
      method: "POST",
      body: data,
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.status==="success"){
        this.props.onDelete(this.props.detail)
      }
      else{
        this.setState({message:json.message})
      }
    })
    .catch((error) => {
      this.setState({message:error})
    })
  }

  handleToggleDetail = (event) => {
    event.preventDefault()
    this.setState({isDetailOpened: !this.state.isDetailOpened})
  }

  handleSendMail = (event) => {
    event.preventDefault()
    const url = "https://mail.google.com/mail/u/1/?fs=1&tf=cm"
      +"&to="+this.props.detail.mail
      +"&su=Re: ゆっくりMovieMaker4に関するお問い合わせ"
      +"&body=お問い合わせありがとうございます。\r\n"
      +"\r\n"
      +"＞" + this.props.detail.msg.replace("\r\n","\r\n＞") +"\r\n"
      +"\r\n"
      +"--------------------------------------------\r\n"
      +"饅頭遣い\r\n"
      +"manju_summoner@manjubox.net\r\n"
      +"https://manjubox.net/\r\n"
      +"--------------------------------------------";
    window.open(encodeURI(url), "_blank");
  }

  render(){
    return(
      <StyledDiv onClick={this.handleToggleDetail}>
        <p className="detailTitle">{this.state.isDetailOpened ? "-" : "+"} {this.props.detail.date} / {this.props.detail.version} <button type="submit" onClick={this.handleDelete} className="deleteDetailButton">削除</button></p>
        <div className="detailBody">
          {this.props.detail.mail.length > 0 && this.props.detail.msg.length > 0 ? <div><a href="/" onClick={this.handleSendMail}>返信: {this.props.detail.mail}</a></div> : null} 
          <span>{this.state.message}</span>

          {this.props.detail.msg.length > 0 ? <pre className="detailMessage">{this.props.detail.msg}</pre> : null }
          {this.state.isDetailOpened ? 
            <>
              <pre>{this.props.detail.env}</pre>
              <div>{this.props.detail.ip}</div>
              <div>{this.props.detail.mail}</div>
            </>
            : null}
        </div>
      </StyledDiv>
    )
  }
}
export default LogDetailView

const StyledDiv=styled.div`
  .deleteDetailButton{
    width: 4rem;
    vertical-align: middle;
    margin-right: 0.5em;
  }
  .detailBody{
    margin-left: 1.5em;
  }
  a{
    color: steelblue;
  }
  .detailTitle{
    font-weight: bold;
  }
  .detailMessage{
    font-family: 'IBM Plex Sans',sans-serif,-apple-system,BlinkMacSystemFont, 'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue';
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.5rem;
  }
  pre{
    font-family: 'IBM Plex Sans',sans-serif,-apple-system,BlinkMacSystemFont, 'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue';
    font-size: 1rem;
  }
`