import React from "react"
import LogsView from "./LogsVIew"
import LoginView from "./LoginView"
import styled from "styled-components"

class ErrorLogView extends React.Component{
  state = {
    isLoggedIn: false,
    username:"",
    message:"",
    isLocal:false,
  }

  handleLoggedIn = (username) => {
    this.setState({
      isLoggedIn: true,
      username: username,
    })
  }

  componentDidMount(){
    var hostName = document.location.hostname;
    if(hostName == "localhost" || hostName == "127.0.0.1"){
      this.setState({isLoggedIn:true, isLocal:true})
      return
    }

    const url = "https://manjubox.net/php/elview.php"
    const data = new FormData()
    data.append("method", "is_login")

    fetch(url,{
        method: "POST",
        body: data,
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.status == "success"){
        this.setState({isLoggedIn:true})
      }
    })
  }

  render(){
    return(
      <StyledDiv>
        {this.state.isLoggedIn ? <LogsView isLocal={this.state.isLocal}/> : <LoginView onLogin={this.handleLoggedIn} isLocal={this.state.isLocal}/>}
      </StyledDiv>
    )
  }
}
export default ErrorLogView

const StyledDiv = styled.div`
  margin: 3rem;
`